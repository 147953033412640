<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
  <div v-if="wq.length" id="water-quality">
    <div class="is-flex is-align-items-center border-bottom pb-2 mb-3">
      <img src="/img/assets/water-quality.png" class="mr-3" alt style="height: 20px"/>
      <p class="has-text-weight-bold mb-0">{{ $t('wq.wq') }}</p>
    </div>

    <template v-if="!hideTable">
      <div v-for="(wq, key) in wq" :key="`water-${key}`" class="mb-4">

        <div
            class="has-text-weight-bold mb-2 pb-1 border-bottom is-flex is-justify-content-space-between is-align-items-center"
            :class="{
        'has-text-danger': (!!isDiseaseAlert(wq.date) ? isDiseaseAlert(wq.date).color === 'danger' : false) || (!!isDeathAlert(wq.date) ? isDeathAlert(wq.date).color === 'danger' : false),
        'has-text-warning': (!!isDiseaseAlert(wq.date) ? isDiseaseAlert(wq.date).color === 'warning' : false) || (!!isDeathAlert(wq.date) ? isDeathAlert(wq.date).color === 'warning' : false),
      }">
        <span class="has-text-dark" style="font-size: 18px">
          {{ $dateFormat(wq.date) }}
          <span v-if="isCycle">- DOC {{ docByCycle(cycle.cycle_id, wq.date) }}</span>
        </span>
          <div class="is-flex">
            <b-tooltip type="is-dark" position="is-left" v-if="!!isDiseaseAlert(wq.date)">
              <b-tag rounded
                     :type="!!isDiseaseAlert(wq.date) ? (isDiseaseAlert(wq.date).color === 'danger' ? 'is-danger' : 'is-warning') : ''"
                     class="cursor-pointer ml-2">
                <b-icon icon="information-outline" size="is-small" class="mr-1"/>
                {{ $t('disease_alert') }}
              </b-tag>
              <template v-slot:content>
            <span class="is-flex is-flex-direction-column has-text-centered has-text-weight-bold">
            <span v-for="(item, key) in isDiseaseAlert(wq.date).disease" :key="key">{{ item }}</span>
            </span>
              </template>
            </b-tooltip>
            <b-tag rounded
                   :type="!!isDeathAlert(wq.date) ? (isDeathAlert(wq.date).color === 'danger' ? 'is-danger' : 'is-warning') : ''"
                   v-if="!!isDeathAlert(wq.date)"
                   class="cursor-pointer ml-2">
              <b-icon icon="information-outline" size="is-small" class="mr-1"/>
              {{ isDeathAlert(wq.date).color === 'danger' ? isDeathAlert(wq.date).data : $t('death_alert') }}
            </b-tag>
          </div>
        </div>

        <div
            v-if="wq.data.data.find(e => e.general.length) || wq.data.data.find(e => e.chemical.length) || wq.data.data.find(e => e.bacteria.length)">

          <div class="b-table mb-2" v-if="wqStandar && wqStandar.length">
            <div class="table-wrapper">
              <table class="table is-bordered">
                <tbody>
                <tr class="header-row">
                  <th style="width: 110px">{{ $t('farm.pond') }}</th>
                  <th style="width: 120px">{{ $t('wq.wq') }}</th>
                  <th>{{ $t('desc') }}</th>
                </tr>
                </tbody>
                <tbody>
                <tr v-for="(item, standarKey) in wqStandar[key].data" :key="standarKey">
                  <td>{{ item.name }}</td>
                  <td class="has-text-centered">
                    <b-icon size="is-medium" icon="check-circle" class="has-text-success" v-if="item.quality === 0"/>
                    <b-icon size="is-medium" icon="alert" class="has-text-warning" v-if="item.quality === 1"/>
                    <b-icon size="is-medium" icon="close-circle" class="has-text-danger" v-if="item.quality === 2"/>
                  </td>
                  <td>
                    <ol class="pl-4 ml-2" v-if="item.desc">
                      <li v-for="(desc, descKey) in item.desc" :key="descKey">{{ desc }}</li>
                    </ol>
                    <span v-else class="ml-2">-</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="b-table mb-2" v-if="wq.data.data.find(e => e.general.length)">
            <div class="table-wrapper">
              <table class="table is-bordered">
                <tbody>
                <tr class="header-row">
                  <th rowspan="2">{{ $t('farm.pond') }}</th>
                  <th rowspan="2">DOC</th>
                  <th :colspan="generalColspan(wq.data.data, wq.data.opts.total_general, 'ph')"
                      v-if="wq.data.data.find(e => e.general.length)" style="min-width: 100px">pH
                  </th>
                  <th v-if="wq.data.data.find(e => e.general.length)" style="min-width: 100px">pH Range</th>
                  <th :colspan="generalColspan(wq.data.data, wq.data.opts.total_general, 'DO')"
                      v-if="wq.data.data.find(e => e.general.length)" style="min-width: 100px">DO
                  </th>
                  <th :colspan="generalColspan(wq.data.data, wq.data.opts.total_general, 'temperature')"
                      v-if="wq.data.data.find(e => e.general.length)">{{ $t('wq.temperature') }}
                  </th>
                  <th :colspan="generalColspan(wq.data.data, wq.data.opts.total_general, 'turbidity')"
                      v-if="wq.data.data.find(e => e.general.length)" style="min-width: 100px">{{ $t('wq.turbidity') }}
                  </th>
                  <th :colspan="generalColspan(wq.data.data, wq.data.opts.total_general, 'water_height')"
                      v-if="wq.data.data.find(e => e.general.length)" style="min-width: 100px">{{ $t('wq.wh') }}
                  </th>
                  <th :colspan="generalColspan(wq.data.data, wq.data.opts.total_general, 'salinity')"
                      v-if="wq.data.data.find(e => e.general.length)" style="min-width: 100px">{{ $t('wq.salinity') }}
                  </th>
                  <th :colspan="generalColspan(wq.data.data, wq.data.opts.total_general, 'oxygen')"
                      v-if="wq.data.data.find(e => e.general.length)">{{ $t('wq.oxygen') }}
                  </th>
                  <th :colspan="generalColspan(wq.data.data, wq.data.opts.total_general, 'orp')"
                      v-if="wq.data.data.find(e => e.general.length)" style="min-width: 100px">ORP
                  </th>
                  <th :colspan="generalColspan(wq.data.data, wq.data.opts.total_general, 'color')"
                      v-if="wq.data.data.find(e => e.general.length)" style="width: 200px;">{{ $t('wq.color') }}
                  </th>
                  <th :colspan="generalColspan(wq.data.data, wq.data.opts.total_general, 'weather')"
                      v-if="wq.data.data.find(e => e.general.length)" style="width: 200px;">{{ $t('wq.weather') }}
                  </th>
                </tr>
                <tr>
                  <th v-for="n in wq.data.opts.total_general.length" :key="`time-ph-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n - 1], 'ph')">
                    <span>{{
                        $timeFormat(wq.data.opts.total_general[(n - 1) % wq.data.opts.total_general.length].created_at)
                      }}</span>
                  </th>
                  <th></th>
                  <th v-for="n in wq.data.opts.total_general.length" :key="`time-DO-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n - 1], 'DO')">
                    <span>{{
                        $timeFormat(wq.data.opts.total_general[(n - 1) % wq.data.opts.total_general.length].created_at)
                      }}</span>
                  </th>
                  <th v-for="n in wq.data.opts.total_general.length" :key="`time-temperature-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n - 1], 'temperature')">
                    <span>{{
                        $timeFormat(wq.data.opts.total_general[(n - 1) % wq.data.opts.total_general.length].created_at)
                      }}</span>
                  </th>
                  <th v-for="n in wq.data.opts.total_general.length" :key="`time-turbidity-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n - 1], 'turbidity')">
                    <span>{{
                        $timeFormat(wq.data.opts.total_general[(n - 1) % wq.data.opts.total_general.length].created_at)
                      }}</span>
                  </th>
                  <th v-for="n in wq.data.opts.total_general.length" :key="`time-water_height-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n - 1], 'water_height')">
                    <span>{{
                        $timeFormat(wq.data.opts.total_general[(n - 1) % wq.data.opts.total_general.length].created_at)
                      }}</span>
                  </th>
                  <th v-for="n in wq.data.opts.total_general.length" :key="`time-salinity-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n - 1], 'salinity')">
                    <span>{{
                        $timeFormat(wq.data.opts.total_general[(n - 1) % wq.data.opts.total_general.length].created_at)
                      }}</span>
                  </th>
                  <th v-for="n in wq.data.opts.total_general.length" :key="`time-oxygen-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n - 1], 'oxygen')">
                    <span>{{
                        $timeFormat(wq.data.opts.total_general[(n - 1) % wq.data.opts.total_general.length].created_at)
                      }}</span>
                  </th>
                  <th v-for="n in wq.data.opts.total_general.length" :key="`time-orp-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n - 1], 'orp')">
                    <span>{{
                        $timeFormat(wq.data.opts.total_general[(n - 1) % wq.data.opts.total_general.length].created_at)
                      }}</span>
                  </th>
                  <th v-for="n in wq.data.opts.total_general.length" :key="`time-color-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n - 1], 'color')">
                    <span>{{
                        $timeFormat(wq.data.opts.total_general[(n - 1) % wq.data.opts.total_general.length].created_at)
                      }}</span>
                  </th>
                  <th v-for="n in wq.data.opts.total_general.length" :key="`time-weather-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n - 1], 'weather')">
                    <span>{{
                        $timeFormat(wq.data.opts.total_general[(n - 1) % wq.data.opts.total_general.length].created_at)
                      }}</span>
                  </th>
                </tr>
                </tbody>
                <tbody>
                <tr v-for="(item, key) in wq.data.data" :key="key" v-if="item.general.find(e => e.show !== false)">
                  <td>{{ item.pond_name }}</td>
                  <td>{{ docByCycle(item.cycle_id, wq.date) }}</td>
                  <td v-for="(data, n) in item.general" :key="`ph-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n], 'ph')">
                      <span :class="{'has-text-danger': isOutLimit('ph', data, wq.data.data)}" class="font-weight-bold">
                        {{ data.ph && data.ph !== -99 ? data.ph : '-' }}
                      </span>
                  </td>
                  <td :class="{'has-text-danger': isOutLimitRangePh(rangePhValue(item.general))}">
                    {{ rangePhValue(item.general) }}
                  </td>
                  <td v-for="(data, n) in item.general" :key="`do-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n], 'DO')">
                      <span :class="{'has-text-danger': isOutLimit('do', data)}" class="font-weight-bold">
                        {{ data.DO && data.DO !== -99 ? data.DO : '-' }}
                      </span>
                  </td>
                  <td v-for="(data, n) in item.general" :key="`temperature-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n], 'temperature')">
                      <span :class="{'has-text-danger': isOutLimit('temperature', data)}" class="font-weight-bold">
                        {{ data.temperature && data.temperature !== -99 ? data.temperature : '-' }}
                      </span>
                  </td>
                  <td v-for="(data, n) in item.general" :key="`turbidity-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n], 'turbidity')">
                      <span :class="{'has-text-danger': isOutLimit('turbidity', data)}" class="font-weight-bold">
                        {{ data.turbidity && data.turbidity !== -99 ? data.turbidity : '-' }}
                      </span>
                  </td>
                  <td v-for="(data, n) in item.general" :key="`water_height-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n], 'water_height')">
                      <span :class="{'has-text-danger': isOutLimit('water_height', data)}" class="font-weight-bold">
                        {{ data.water_height && data.water_height !== -99 ? data.water_height : '-' }}
                      </span>
                  </td>
                  <td v-for="(data, n) in item.general" :key="`salinity-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n], 'salinity')">
                      <span :class="{'has-text-danger': isOutLimit('salinity', data)}" class="font-weight-bold">
                        {{ data.salinity && data.salinity !== -99 ? data.salinity : '-' }}
                      </span>
                  </td>
                  <td v-for="(data, n) in item.general" :key="`oxygen-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n], 'oxygen')">
                      <span :class="{'has-text-danger': isOutLimit('oxygen', data)}" class="font-weight-bold">
                        {{ data.oxygen && data.oxygen !== -99 ? data.oxygen : '-' }}
                      </span>
                  </td>
                  <td v-for="(data, n) in item.general" :key="`orp-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n], 'orp')">
                      <span :class="{'has-text-danger': isOutLimit('orp', data)}" class="font-weight-bold">
                        {{ data.orp && data.orp !== -99 ? data.orp : '-' }}
                      </span>
                  </td>
                  <td v-for="(data, n) in item.general" :key="`color-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n], 'color')">
                      <span :class="{'has-text-danger': isOutLimit('color', data)}" class="font-weight-bold">
                        {{ data.color && data.color !== -99 ? $getColorLabel(data.color) : '-' }}
                      </span>
                  </td>
                  <td v-for="(data, n) in item.general" :key="`weather-${n}`"
                      v-if="generalTime(wq.data.data, wq.data.opts.total_general[n], 'weather')">
                      <span :class="{'has-text-danger': isOutLimit('weather', data)}" class="font-weight-bold">
                        {{ data.weather && data.weather !== -99 ? $getWeatherLabel(data.weather) : '-' }}
                      </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="b-table mb-2" v-if="wq.data.data.find(e => e.chemical.length)">
            <div class="table-wrapper">
              <table class="table is-bordered">
                <tbody>
                <tr class="header-row">
                  <th rowspan="2">{{ $t('farm.pond') }}</th>
                  <th rowspan="2">DOC</th>
                  <th v-if="findWqField(wq.data.data, 'chemical', 'tom')" rowspan="2">TOM</th>
                  <th v-if="findWqField(wq.data.data, 'chemical', 'alkalinity')" rowspan="2">Alkalinity</th>
                  <th v-if="findWqField(wq.data.data, 'chemical', 'po4')" rowspan="2">PO4</th>
                  <th v-if="findWqField(wq.data.data, 'chemical', 'no3')" rowspan="2">NO3</th>
                  <th v-if="findWqField(wq.data.data, 'chemical', 'no2')" rowspan="2">NO2</th>
                  <th v-if="findWqField(wq.data.data, 'chemical', 'nh4')" rowspan="2">NH4</th>
                  <th v-if="findWqField(wq.data.data, 'chemical', 'nh3')" rowspan="2">NH3</th>
                  <th v-if="findWqField(wq.data.data, 'chemical', 'tan')" rowspan="2">TAN</th>
                  <th v-if="findWqField(wq.data.data, 'chemical', 'co3')" rowspan="2">CO3</th>
                  <th v-if="findWqField(wq.data.data, 'chemical', 'hco3')" rowspan="2">HCO3</th>
                  <th v-if="findWqField(wq.data.data, 'chemical', 'ca')" rowspan="2">Ca</th>
                  <th v-if="findWqField(wq.data.data, 'chemical', 'mg')" rowspan="2">Mg</th>
                  <th v-if="findWqField(wq.data.data, 'chemical', 'hard')" rowspan="2">Hardness</th>
                </tr>
                </tbody>
                <tbody v-for="(wqData, key) in wq.data.data" :key="key">
                <tr v-for="(item, key) in wqData.chemical" :key="key">
                  <td>{{ wqData.pond_name }}</td>
                  <td>{{ docByCycle(item.cycle_id, wq.date) }}</td>
                  <td v-if="findWqField(wq.data.data, 'chemical', 'tom')">
                      <span :class="{'has-text-danger': isOutLimit('tom', item)}" class="font-weight-bold">
                        {{ item && item.tom !== -99 ? item.tom : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'chemical', 'alkalinity')">
                      <span :class="{'has-text-danger': isOutLimit('alkalinity', item)}" class="font-weight-bold">
                        {{ item && item.alkalinity !== -99 ? item.alkalinity : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'chemical', 'po4')">
                      <span :class="{'has-text-danger': isOutLimit('po4', item)}" class="font-weight-bold">
                        {{ item && item.po4 !== -99 ? item.po4 : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'chemical', 'no3')">
                      <span :class="{'has-text-danger': isOutLimit('no3', item)}" class="font-weight-bold">
                        {{ item && item.no3 !== -99 ? item.no3 : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'chemical', 'no2')">
                      <span :class="{'has-text-danger': isOutLimit('no2', item)}" class="font-weight-bold">
                        {{ item && item.no2 !== -99 ? item.no2 : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'chemical', 'nh4')">
                      <span :class="{'has-text-danger': isOutLimit('nh4', item)}" class="font-weight-bold">
                        {{ item && item.nh4 !== -99 ? item.nh4 : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'chemical', 'nh3')">
                      <span :class="{'has-text-danger': isOutLimit('nh3', item)}" class="font-weight-bold">
                        {{ item && item.nh3 !== -99 ? item.nh3 : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'chemical', 'tan')">
                      <span :class="{'has-text-danger': isOutLimit('tan', item)}" class="font-weight-bold">
                        {{ item && item.tan !== -99 ? item.tan : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'chemical', 'co3')">
                      <span :class="{'has-text-danger': isOutLimit('co3', item)}" class="font-weight-bold">
                        {{ item && item.co3 !== -99 ? item.co3 : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'chemical', 'hco3')">
                      <span :class="{'has-text-danger': isOutLimit('hco3', item)}" class="font-weight-bold">
                        {{ item && item.hco3 !== -99 ? item.hco3 : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'chemical', 'ca')">
                      <span :class="{'has-text-danger': isOutLimit('ca', item)}" class="font-weight-bold">
                        {{ item && item.ca !== -99 ? item.ca : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'chemical', 'mg')">
                      <span :class="{'has-text-danger': isOutLimit('mg', item)}" class="font-weight-bold">
                        {{ item && item.mg !== -99 ? item.mg : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'chemical', 'hard')">
                      <span :class="{'has-text-danger': isOutLimit('hard', item)}" class="font-weight-bold">
                        {{ item && item.hard !== -99 ? item.hard : '-' }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="b-table mb-2" v-if="wq.data.data.find(e => e.bacteria.length)">
            <div class="table-wrapper">
              <table class="table is-bordered">
                <tbody>
                <tr class="header-row">
                  <th rowspan="2">{{ $t('farm.pond') }}</th>
                  <th rowspan="2">DOC</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'tbc')" rowspan="2">TBC</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'tvc')" rowspan="2">TVC</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'tvctbc')" rowspan="2">TVC/TBC(%)</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'yvc')" rowspan="2">YVC</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'gvc')" rowspan="2">GVC</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'bvc')" rowspan="2">BVC</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'lbc')" rowspan="2">LBC</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'bga')" rowspan="2">%BGA</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'ga')" rowspan="2">%GA</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'gga')" rowspan="2">%GGA</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'dino')" rowspan="2">%DINO</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'dia')" rowspan="2">%DIA</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'pro')" rowspan="2">%PRO</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'eug')" rowspan="2">%EUG</th>
                  <th v-if="findWqField(wq.data.data, 'bacteria', 'plankton')" rowspan="2">PLANKTON</th>
                </tr>
                </tbody>
                <tbody v-for="(wqData, key) in wq.data.data" :key="key">
                <tr v-for="(item, key) in wqData.bacteria" :key="key">
                  <td>{{ wqData.pond_name }}</td>
                  <td>{{ docByCycle(item.cycle_id, wq.date) }}</td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'tbc')">
                      <span :class="{'has-text-danger': isOutLimit('tbc', item)}" class="font-weight-bold">
                        {{ item && item.tbc !== -99 ? $exponential(item.tbc) : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'tvc')">
                      <span :class="{'has-text-danger': isOutLimit('tvc', item)}" class="font-weight-bold">
                        {{ item && item.tvc !== -99 ? $exponential(item.tvc) : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'tvctbc')">
                      <span :class="{'has-text-danger': isOutLimit('tvctbc', item)}" class="font-weight-bold">
                        {{ item && item.tvctbc !== -99 ? $number(item.tvctbc) : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'yvc')">
                      <span :class="{'has-text-danger': isOutLimit('yvc', item)}" class="font-weight-bold">
                        {{ item && item.yvc !== -99 ? $exponential(item.yvc) : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'gvc')">
                      <span :class="{'has-text-danger': isOutLimit('gvc', item)}" class="font-weight-bold">
                        {{ item && item.gvc !== -99 ? $exponential(item.gvc) : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'bvc')">
                      <span :class="{'has-text-danger': isOutLimit('bvc', item)}" class="font-weight-bold">
                        {{ item && item.bvc !== -99 ? $exponential(item.bvc) : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'lbc')">
                      <span :class="{'has-text-danger': isOutLimit('lbc', item)}" class="font-weight-bold">
                        {{ item && item.lbc !== -99 ? $exponential(item.lbc) : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'bga')">
                      <span :class="{'has-text-danger': isOutLimit('bga', item)}" class="font-weight-bold">
                        {{ item && item.bga !== -99 ? item.bga : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'ga')">
                      <span :class="{'has-text-danger': isOutLimit('ga', item)}" class="font-weight-bold">
                        {{ item && item.ga !== -99 ? item.ga : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'gga')">
                      <span :class="{'has-text-danger': isOutLimit('gga', item)}" class="font-weight-bold">
                        {{ item && item.gga !== -99 ? item.gga : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'dino')">
                      <span :class="{'has-text-danger': isOutLimit('dino', item)}" class="font-weight-bold">
                        {{ item && item.dino !== -99 ? item.dino : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'dia')">
                      <span :class="{'has-text-danger': isOutLimit('dia', item)}" class="font-weight-bold">
                        {{ item && item.dia !== -99 ? item.dia : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'pro')">
                      <span :class="{'has-text-danger': isOutLimit('pro', item)}" class="font-weight-bold">
                        {{ item && item.pro !== -99 ? item.pro : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'eug')">
                      <span :class="{'has-text-danger': isOutLimit('eug', item)}" class="font-weight-bold">
                        {{ item && item.eug !== -99 ? item.eug : '-' }}</span>
                  </td>
                  <td v-if="findWqField(wq.data.data, 'bacteria', 'plankton')">
                      <span :class="{'has-text-danger': isOutLimit('plankton', item)}" class="font-weight-bold">
                        {{ item && item.plankton !== -99 ? $exponential(item.plankton) : '-' }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <p class="has-text-centered" v-else>{{ $t('no') }} Data</p>
      </div>
    </template>

    <div v-if="type === 'Monthly'">
      <div v-for="(item, key) in wqCharts" :key="`water-charts-${key}`" class="mb-4">
        <p class="border-bottom pb-1 mb-2 has-text-weight-bold has-text-grey">{{ item.pond_name }}</p>

        <div v-if="item.charts_morning.length" class="border-bottom mb-2">
          <p class="has-text-weight-bold mb-2">{{ $t('wq.general') }} - {{ $t('wq.morning') }}</p>
          <div class="columns">
            <div class="col-4 px-0 border" v-for="(chart, k) in item.charts_morning" :key="k">
              <p class="font-weight-bold mt-3 mb-0 px-3">{{ getWqLabel(chart.name) }}</p>
              <apexchart type="area" height="200" :options="chart.options"
                         :series="[chart]"></apexchart>
            </div>
          </div>
        </div>

        <div v-if="item.charts_afternoon.length" class="border-bottom mb-2">
          <p class="has-text-weight-bold mb-2">{{ $t('wq.general') }} - {{ $t('wq.afternoon') }}</p>
          <div class="columns">
            <div class="col-4 px-0 border" v-for="(chart, k) in item.charts_afternoon" :key="k">
              <p class="font-weight-bold mt-3 mb-0 px-3">{{ getWqLabel(chart.name) }}</p>
              <apexchart type="area" height="200" :options="chart.options" :series="[chart]"></apexchart>
            </div>
          </div>
        </div>

        <div v-if="item.charts_evening.length" class="border-bottom mb-2">
          <p class="has-text-weight-bold mb-2">{{ $t('wq.general') }} - {{ $t('wq.evening') }}</p>
          <div class="columns">
            <div class="col-4 px-0 border" v-for="(chart, k) in item.charts_evening" :key="k">
              <p class="font-weight-bold mt-3 mb-0 px-3">{{ getWqLabel(chart.name) }}</p>
              <apexchart type="area" height="200" :options="chart.options" :series="[chart]"></apexchart>
            </div>
          </div>
        </div>

        <div v-if="item.charts_chemical.length" class="border-bottom mb-2">
          <p class="has-text-weight-bold mb-2">{{ $t('wq.chemical') }}</p>
          <div class="columns">
            <div class="col-4 px-0 border" v-for="(chart, k) in item.charts_chemical" :key="k">
              <p class="font-weight-bold mt-3 mb-0 px-3">{{ getWqLabel(chart.name) }}</p>
              <apexchart type="area" height="200" :options="chart.options" :series="[chart]"></apexchart>
            </div>
          </div>
        </div>

        <div v-if="item.charts_bacteria.length" class="border-bottom mb-2">
          <p class="has-text-weight-bold mb-2">{{ $t('wq.biological') }}</p>
          <div class="columns">
            <div class="col-4 px-0 border" v-for="(chart, k) in item.charts_bacteria" :key="k">
              <p class="font-weight-bold mt-3 mb-0 px-3">{{ getWqLabel(chart.name) }}</p>
              <apexchart type="area" height="200" :options="chart.options" :series="[chart]"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ReportWaterQuality",
  props: {
    wq: Array,
    wqCharts: Array,
    wqStandar: Array,
    wqs: Array,
    stocks: Array,
    type: String,
    diseaseDate: Array,
    deathDate: Array,
    isCycle: Boolean,
    cycle: Object,
    hideTable: Boolean,
  },
  components: {
    apexchart: VueApexCharts,
  },
  methods: {
    isDiseaseAlert(date) {
      if (this.diseaseDate) {
        let find = this.diseaseDate.find(e => e.date === date)
        if (find) {
          return find
        }
      }
      return false
    },
    isDeathAlert(date) {
      if (this.deathDate) {
        let find = this.deathDate.find(e => e.date === date)
        if (find) {
          return find
        }
      }
      return false
    },

    generalColspan(item, time, type) {
      let res = 0

      for (let i = 0; i < time.length; i++) {
        if (this.generalTime(item, time[i], type)) ++res
      }

      return res
    },

    generalTime(item, time, type) {
      if (item && time && type)
        return item.find(e => {
          let data_time = e.general_time.find(f => f.time === time.created_at)

          if (data_time) {
            return data_time.data[type]
          }

          return false
        })
      return false
    },

    findWqField(wq, type, field) {
      for (let i = 0; i < wq.length; i++) {
        let wqData = wq[i]

        for (let i = 0; i < wqData[type].length; i++) {
          let item = wqData[type]
          let find = item.find(e => e[field] !== -99)
          if (find) return true
        }
      }

      return false
    },

    isOutLimit(type, data, allData = null) {
      let find = this.wqs.find(e => e.name.toLowerCase() === type)

      if (type === 'ph') {
        let hour = this.$moment(data.created_at).hour()
        let morning_morning = this.wqs.find(e => e.name === 'ph_morning_to_morning')
        let morning_evening = this.wqs.find(e => e.name === 'ph_morning_to_evening')
        let evening_evening = this.wqs.find(e => e.name === 'ph_evening_to_evening')

        let yesterdayData = this.wq.find(e => e.date === this.$moment(data.created_at).startOf('day').add(-1, 'day').valueOf())
        let pond_id = data.pond_id
        if (yesterdayData && yesterdayData.data && yesterdayData.data.data) {
          yesterdayData = yesterdayData.data.data.find(e => e.pond_id === pond_id) || []
        } else{
          yesterdayData = []
        }

        if (hour < 12) {
          if (morning_morning && yesterdayData.length) {
            let findData = cloneDeep(yesterdayData).find(e => e.pond_id === data.pond_id)
            if (findData) {
              findData = findData.general.sort((a, b) => b.created_at > a.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() < 12)
              if (findData) {
                let diff = data.ph - findData.ph
                return diff > morning_morning.max_value || diff < -morning_morning.max_value
              }
            }
          }
        } else {
          if (morning_evening) {
            let findData = this._.cloneDeep(allData).sort((a, b) => b.created_at > a.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() < 12)
            if (findData) {
              let diff = data.ph - findData.ph
              diff = diff > morning_evening.max_value || diff < -morning_evening.max_value
              if (diff) return true
            }
          }

          if (evening_evening && yesterdayData.length) {
            let findData = cloneDeep(yesterdayData).find(e => e.pond_id === data.pond_id)
            if (findData) {
              findData = findData.general.sort((a, b) => a.created_at > b.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() >= 12)
              if (findData) {
                let diff = data.ph - findData.ph
                return diff > evening_evening.max_value || diff < -evening_evening.max_value
              }
            }
          }
        }
      }

      if (find && data && (data[type] !== -99 || data.type !== undefined)) {
        if (typeof find.min_value === 'number' && typeof find.max_value === 'number')
          return !(data[type] >= find.min_value && data[type] <= find.max_value)
        return data[type] < find.min_value || data[type] > find.max_value
      }

      return false
    },

    rangePhValue(data) {
      let diff = '-'

      let hour = this.$moment(data.created_at).hour()
      // let morning_morning = this.wqs.find(e => e.name === 'ph_morning_to_morning')
      let morning_evening = this.wqs.find(e => e.name === 'ph_morning_to_evening')
      // let evening_evening = this.wqs.find(e => e.name === 'ph_evening_to_evening')

      // if (hour < 12) {
      // if (morning_morning && this.yesterdayData.length) {
      //   let findData = cloneDeep(this.yesterdayData).find(e => e.pond_id === data.pond_id)
      //   if (findData) {
      //     findData = findData.general.sort((a, b) => b.created_at > a.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() < 12)
      //     if (findData) {
      //       let diff = data.ph - findData.ph
      //       return diff > morning_morning.max_value || diff < -morning_morning.max_value
      //     }
      //   }
      // }
      // } else {
      //   if (morning_evening) {
      let morningData = this._.cloneDeep(data).sort((a, b) => b.created_at > a.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() < 12 && e.ph && e.ph !== -99)
      let eveningData = this._.cloneDeep(data).sort((a, b) => a.created_at > b.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() >= 12 && e.ph && e.ph !== -99)
      if (morningData && eveningData) {
        diff = Math.round((eveningData.ph - morningData.ph) * 10) / 10
      }
      // }

      // if (evening_evening && this.yesterdayData.length) {
      //   let findData = cloneDeep(this.yesterdayData).find(e => e.pond_id === data.pond_id)
      //   if (findData) {
      //     findData = findData.general.sort((a, b) => a.created_at > b.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() >= 12)
      //     if (findData) {
      //       let diff = data.ph - findData.ph
      //       return diff > evening_evening.max_value || diff < -evening_evening.max_value
      //     }
      //   }
      // }
      // }
      return diff
    },

    isOutLimitRangePh (value) {
      let morning_evening = this.wqs.find(e => e.name === 'ph_morning_to_evening')

      if (morning_evening) {
        return value > morning_evening.max_value || value < -morning_evening.max_value
      }

      return false
    },

    getWqSetting(type) {
      return this.wqs.find(e => e.name === type)
    },

    getWqLabel(value) {
      switch (value) {
        case 'ph':
          return 'pH'
        case 'do':
        case 'orp':
          return value.toUpperCase()
        case 'temperature':
          return this.$t('wq.temperature')
        case 'turbidity':
          return this.$t('wq.turbidity')
        case 'water_height':
          return this.$t('wq.wh')
        case 'salinity':
          return this.$t('wq.salinity')
        case 'oxygen':
          return this.$t('wq.oxygen')

        case 'alkalinity':
          return 'Alkalinity'
        case 'tom':
        case 'po4':
        case 'no3':
        case 'no2':
        case 'nh4':
        case 'nh3':
        case 'tan':
        case 'co3':
        case 'hco3':
          return value.toUpperCase()
        case 'ca':
          return 'Ca'
        case 'mg':
          return 'Mg'
        case 'hard':
          return 'Hardness'

        case 'tvctbc':
          return 'TVC/TBC(%)'
        case 'tbc':
        case 'yvc':
        case 'gvc':
        case 'lbc':
        case 'tvc':
          return value.toUpperCase()
        case 'plankton':
          return 'Total Plankton'
        case 'bga':
        case 'ga':
        case 'gga':
        case 'dino':
        case 'dia':
        case 'pro':
        case 'eug':
          return '%' + value.toUpperCase()
      }
      return value
    },

    docByCycle(cycle_id, date) {
      let stock = this.stocks.find(e => e.cycle_id === cycle_id)

      if (stock) return this.$getDOC(stock.stock_date, date)

      return '-'
    },
  }
}
</script>
