<template>

  <div v-if="warehouse && warehouse.length">
    <div class="is-flex is-align-items-center border-bottom pb-2 mb-3">
      <img src="/img/assets/use-item.png" class="mr-3" alt style="height: 20px"/>
      <p class="has-text-weight-bold mb-0">{{ $t('warehouse.warehouse') }}</p>
    </div>

    <div class="columns is-tablet mb-5">
      <div class="column is-3-tablet" v-for="(item, key) in warehouse" :key="key">
        <article class="card avoid-break">
          <div class="card-content p-4">
            <div class="has-text-dark is-block">
              <b-tag :type="getCategoryColor(item.category_name)">{{ $t('warehouse.' + item.category_name.toLowerCase()) }}</b-tag>
              <h4 class="is-size-6 has-text-weight-bold my-2">{{ item.product_name }}</h4>
              <div class="is-flex mt-2">
                <span class="has-text-weight-bold is-size-7 mr-2" :class="{
                  'has-text-success': $number(item.qty) > (item.min_qty ? item.min_qty - 1 : 0),
                  'has-text-danger': $number(item.qty) <= (item.min_qty ? item.min_qty - 1 : 0),
                }">
                  {{ $number(item.qty) }} {{ item.unit }}
                </span>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportWarehouse",
  props: {
    warehouse: Array,
  },
  methods: {
    getCategoryColor(category_id) {
      switch (category_id) {
        case 'Feed':
          return 'is-info'
        case 'Chemical':
          return 'is-success'
        case 'Probiotics':
          return 'is-warning'
        default:
          return 'is-secondary is-light'
      }
    },
  }
}
</script>
